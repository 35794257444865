var prevSubmenuID = "";
var prevIsFront = false;
var prevSubmenuitemID = "";


$(document).ready(function() {

    $(window).scroll(function (event) {
        var scroll = $(window).scrollTop();
        if(scroll > 30) {
            $('.submenuline').css('box-shadow', '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)');
        }
        else {
            $('.submenuline').css('box-shadow', 'none');
        }
        if(scroll > 40) {
            $('.menuline').css('box-shadow', '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)');
        }
        else {
            $('.menuline').css('box-shadow', 'none');
        }
    });
    //box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);

    window.addEventListener("popstate", function(event) {
        loadLocalPage(window.location.href, false);
        openCorrespondingSubmenu(window.location.href, false);
    });

    $( window ).resize(function() {
        if(prevSubmenuID != '') {
            var newLeftOffset = $('#' + prevSubmenuitemID).offset().left;
            var newWidth = $('#' + prevSubmenuitemID).width();
            $('#highlightLineSub').css("left", newLeftOffset);
            $('#highlightLineSub').css("width", newWidth);
        }
        var menuitem = $('#' + $('#' + prevSubmenuitemID).closest('.submenuline').data('menuitem'));
        if(menuitem.length != 0) {
            var pos = $(menuitem).offset().left + $(menuitem).width()/2 - $('#highlighter').width()/2;
            $('#highlighter').css({left: pos});
        }
    });


    $(document).on("mouseenter", ".img-container", function() {
        $('.prevImage').fadeIn('normal');
        $('.nextImage').fadeIn('normal');
    });
    $(document).on("mouseleave", ".img-container", function() {
        $('.prevImage').fadeOut('normal');
        $('.nextImage').fadeOut('normal');
    });

    $(document).on("click", ".slideshow-indicator .dot", function() {
        clearInterval(slideshowInterval);
        $($('.single-image').get(currentImageIndex)).fadeOut(400);
        $('.slideshow-indicator .dot').removeClass('selected');
        $('div#' + $(this).data('img')).closest('.single-image').delay(100).fadeIn(400);
        currentImageIndex = $(this).data('index');
        $($('.slideshow-indicator .dot').get(currentImageIndex)).addClass('selected');
    });


    $(document).on('click', '.prevImage, .nextImage', function() {
        clearInterval(slideshowInterval);
        $($('.single-image').get(currentImageIndex)).fadeOut(400);
        if($(this).hasClass('nextImage')) {
            if(currentImageIndex < $('.single-image').length - 1) {
                var nextImage = $($('.single-image').get(currentImageIndex + 1));
                nextImage.delay(100).fadeIn(400);
                currentImageIndex++;
            }
            else {
                $($('.single-image').get(0)).delay(100).fadeIn(400);
                currentImageIndex = 0;
            }
        }
        else {
            if(currentImageIndex > 0) {
                var prevImage = $($('.single-image').get(currentImageIndex - 1));
                prevImage.delay(100).fadeIn(400);
                currentImageIndex--;
            }
            else {
                $($('.single-image').get($('.single-image').length - 1)).delay(100).fadeIn(400);
                currentImageIndex = $('.single-image').length - 1;
            }
        }
        $('.slideshow-indicator .dot').removeClass('selected');
        $($('.slideshow-indicator .dot').get(currentImageIndex)).addClass('selected');
    });


    $( document ).on('click', 'a', function( event ) {
        event.preventDefault(); 

        var url = $(this).attr("href");
        var isLocal = (url.indexOf(BASE_URL) !== -1 && url != BASE_URL) ? true : false;

        if(url.indexOf("mailto") !== -1) {
            window.open(url, '_blank');
        }
        else {
            if(url == '#') { // clicked on a hidden email
                // do nothing
            }
            else if(isLocal) {
                if($(this).hasClass("menuitem")) {
                    $('.content').css("transition", "margin-top 0.4s");
                    showSubMenu($(this), $(this).data("submenu"), $(this).data("front"), url);
                }
                else if($(this).hasClass("submenuitem")) {
                    loadLocalPage(url, true);
                    var newLeftOffset = $(this).offset().left;
                    var newWidth = $(this).width();
                    if ($('#highlightLineSub').css('display') == 'block') {
                        $('#highlightLineSub').animate({left: newLeftOffset, width: newWidth}, {duration: 200, easing: 'easeOutQuad'});
                    }
                    else {
                        $('#highlightLineSub').css({left: newLeftOffset, width: newWidth, display: 'block'});
                    }
                    prevSubmenuitemID = $(this).attr('id');
                }
                else {
                    loadLocalPage(url, true);
                    $('html, body').animate({ scrollTop: ($("html").offset().top)}, 'normal');
                }
                return false;
            }
            else {
                if(url == BASE_URL) window.location.href = url; // load front page in same window
                else window.open(url, '_blank');
                return false;
            }
        }
    });


    function showSubMenu(menuitem, submenuID, isFront, url) { // url only used for front page
        if(prevSubmenuitemID != "" && $("#" + submenuID).find("#" + prevSubmenuitemID).length != 0) { // currently opened submenuitem is located in clicked main menu
            $('#highlightLineSub').css("display", "block");
        }
        else {
            $('#highlightLineSub').css("display", "none");
        }
        
        if(prevSubmenuID != "") {
            if(isFront) $('#' + prevSubmenuID).slideUp();
            else $('#' + prevSubmenuID).hide();
        }
        
        if(!isFront) {
            var pos = $(menuitem).offset().left + $(menuitem).width()/2 - $('#highlighter').width()/2;
            if(prevSubmenuID == "" || prevIsFront) {
                $('.highlightPathSub').css("display", "block");
                $('#highlighter').css("left", pos);
                $('#highlighter').css("display", "block");
                prevIsFront = false;
                $('#' + submenuID).slideDown();
                prevSubmenuID = submenuID;
            }
            else {
                $('.highlightPathSub').css("display", "block");
                $('#highlighter').animate({left: pos}, {duration: 200, easing: 'easeOutQuad'});
                $('#' + submenuID).show();
            }
            $('.content').css("margin-top", "151px");
        }
        else {
            loadLocalPage(url, true);
            $('#highlighter').css("display", "none");
            $('#' + prevSubmenuID).slideUp();
            $('.highlightPathSub').css("display", "none");
            $('#highlightLineSub').css("display", "none");
            $('.content').css("margin-top", "106px");
            prevSubmenuitemID = ""; // don't highlight submenu item when reopening submenu after going to front page
        }
        
        if(isFront) prevSubmenuID = "";
        else prevSubmenuID = submenuID;
        prevIsFront = isFront;
    }

    function loadLocalPage(url, pushToHistory) {
        $.ajax({
            url: ajax_url,
            type: "POST",
            data: { action: 'ajax_loadcontent', post_url: url },
            dataType: "html",
            success: function( response ) {
                $(".content").hide();
                $(".content").html(response);
                styleBackButton();
                $(".content").fadeIn('normal');
            },
            error: function( jqXHR, textStatus ) {
                alert( "Request failed: " + textStatus );
            }
        });
        
        if(pushToHistory) history.pushState(null, null, url);
    }

    function styleBackButton() {
        $(".content a:contains('Zurück'), .content a:contains('Back')").addClass('backButton');
    }
    
    styleBackButton();

});

function openCorrespondingSubmenu(url, starting) {
    var submenuitem = $('nav').find("a.submenuitem").filter(function() { return url.indexOf($(this).attr('href')) !== -1; });
    if(submenuitem.length != 0) {
        var menuitem = $('#' + $(submenuitem).closest('.submenuline').data('menuitem'));

        var prevSubItem = $('.submenuline').filter(function() { return $(this).css('display') == 'block'; });
        if(prevSubItem.length != 0) {
            $(prevSubItem).hide();
            $(submenuitem).closest('.submenuline').show();
        }
        else {
            if(!starting) $(submenuitem).closest('.submenuline').slideDown();
            else $(submenuitem).closest('.submenuline').show();
        }
        $('.highlightPathSub').show();
        var newLeftOffset = $(submenuitem).offset().left;
        var newWidth = $(submenuitem).width();
        if ($('#highlightLineSub').css('display') == 'block') {
            $('#highlightLineSub').animate({left: newLeftOffset, width: newWidth}, {duration: 200, easing: 'easeOutQuad'});
        }
        else {
            $('#highlightLineSub').css({left: newLeftOffset, width: newWidth, display: 'block'});
        }

            var pos = $(menuitem).offset().left + $(menuitem).width()/2 - $('#highlighter').width()/2;
        if($('#highlighter').css('display') == 'block') {
            $('#highlighter').animate({left: pos}, {duration: 200, easing: 'easeOutQuad'});
        }
        else {
            $('#highlighter').css("left", pos);
            $('#highlighter').css("display", "block");
        }

        prevSubmenuID = $(submenuitem).closest('.submenuline').attr('id');
        prevIsFront = false;
        prevSubmenuitemID = $(submenuitem).attr('id');

        $('.content').css("margin-top", "151px");
    }
    else {
        prevSubmenuID = "";
        prevIsFront = true;
        prevSubmenuitemID = "";
    }
    
}

$(window).load(function() {
    openCorrespondingSubmenu(window.location.href, true);
    $('html, body').animate({ scrollTop: ($("html").offset().top)}, 'normal');
});


var captchaResponse = '';
var currentEmailPostID = -1;

function showEmails(element, postID) {
    currentEmailPostID = postID;
    $(element).replaceWith('<div id="' + postID + '-captcha" class="recaptcha"></div>');
    grecaptcha.render( postID + '-captcha', {
        'sitekey' : '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
        'callback' : verifyCallback,
        'theme' : 'light'
    });
}

var verifyCallback = function(response) {
    captchaResponse = response;
    $.ajax({
        url: ajax_url,
        type: "POST",
        data: { action: 'ajax_show_emails', g_recaptcha_response: response, post_id: currentEmailPostID },
        dataType: "json",
        success: function( response ) {
            if(response.success) {
                $('#' + currentEmailPostID + '-captcha').replaceWith('<a href="#" class="email-hidden" onclick="showEmails(this, ' + currentEmailPostID + ');">anzeigen</a>'); // reset to default before getting all hidden email objects
                var i = 0;
                $('a.email-hidden').each(function() {
                    $(this).replaceWith('<a href="mailto:' + response.data[i] + '" class="highlightedText">' + response.data[i] + '</a>');
                    i++;
                });
            }
            else {
                $('#' + currentEmailPostID + '-captcha').replaceWith('<span style="color: red;">Zugriff verweigert!</span>');
            }
        },
        error: function( jqXHR, textStatus ) {
            alert( "Request failed: " + textStatus );
        }
    });
};


/*

var emailKuerzel = '';
var captchaResponse = '';

var verifyCallback = function(response) {
    captchaResponse = response;
    if(emailKuerzel != '') {
        $.ajax({
            url: "<?php echo $host; ?>getEmail.php?kuerzel=" + emailKuerzel + "&g-recaptcha-response=" + captchaResponse,
            type: "GET",
            dataType: "html",
            success: function( response ) {
                if(response != 'bot') {
                $('#' + emailKuerzel + '-captcha').replaceWith('<a href="mailto:' + response + '" class="highlightedText">' + response + '</a>');
                }
                else {
                $('#' + emailKuerzel + '-captcha').replaceWith('<span style="color: red;">Zugriff verweigert!</span>');
                }
            },
            error: function( jqXHR, textStatus ) {
                alert( "Request failed: " + textStatus );
            }
        });
    }
};*/

/*
function showEmails(kuerzel) {
    emailKuerzel = kuerzel;
    $('#email-' + emailKuerzel).replaceWith('<div id="' + emailKuerzel + '-captcha"></div>');
    grecaptcha.render( kuerzel + '-captcha', {
        'sitekey' : '6LcwchkTAAAAAIX4gG3G0nbE-z_8qRZGQwJHUHhZ',
        'callback' : verifyCallback,
        'theme' : 'light'
    });
}*/